<div class="row"
  *ngIf="program && ready">
  <div class="col-12 col-lg-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title d-flex justify-content-between align-items-center">
          <div>
            {{ program.name }}
          </div>
          <div>
            <a [routerLink]="null"
              role="button"
              class="text-muted mr-2"
              [tooltip]="'edit' | translate"
              (click)="openEditModal()">
              <i class="far fa-edit"></i>
            </a>
          </div>
        </h5>
        <text-info icon="building"
          *ngIf="program.faculty">
          <a [routerLink]="['../../../faculties', program.faculty._id]">
            {{ program.faculty.name }}
          </a>
        </text-info>
        <text-info icon="building"
          *ngFor="let f of program.otherFaculties">
          <a [routerLink]="['../../../faculties', f._id]">
            {{ f.name }}
          </a>
        </text-info>
        <div
          *ngIf="program.secretaryFaculty && (program.otherFacultiesIds?.length > 0 || program.secretaryFaculty._id !== program.faculty._id)"
          class="mt-3">
          <div class="text-muted small mb-2">
            {{ 'secretary-faculty' | translate }}
          </div>
          <text-info icon="building">
            <a [routerLink]="['../../../faculties', program.secretaryFaculty._id]">
              {{ program.secretaryFaculty.name }}
            </a>
          </text-info>
        </div>
        <div *ngIf="coordinator"
          class="mt-3">
          <span class="badge badge-info">
            {{ 'coordinator' | translate }}
          </span>
          <div>
            <user-file-link [user]="coordinator"></user-file-link>
          </div>
          <div *ngIf="coordinator.email">
            <email-link [user]="coordinator"></email-link>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        {{ 'lines-of-research' | translate }}
      </div>
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center"
          *ngFor="let lr of linesOfResearch">
          <div>
            {{ lr.name }}
          </div>
          <div *ngIf="edit">
            <a [routerLink]="null"
              role="button"
              class="text-muted mr-2"
              [tooltip]="'edit' | translate"
              (click)="openEditLineModal(lr)">
              <i class="far fa-edit"></i>
            </a>
            <a [routerLink]="null"
              role="button"
              class="text-muted"
              [tooltip]="'remove' | translate"
              (click)="removeLineOfResearch(lr)">
              <i class="far fa-trash"></i>
            </a>
          </div>
        </div>
        <div class="list-group-item"
          *ngIf="linesOfResearch.length <= 0">
          <i>
            {{ 'empty-lines-of-research' | translate}}
          </i>
        </div>
        <div class="list-group-item"
          *ngIf="edit">
          <input type="text"
            class="form-control form-control-sm"
            [placeholder]="'new-line-of-research' | translate"
            name="newLineResearch"
            [(ngModel)]="newLineResearch">
          <div class="w100 text-right">
            <button class="btn btn-sm btn-outline-dark mt-2"
              type="button"
              (click)="addLineOfResearch()">
              {{ 'add' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-6">
    <div class="card">
      <div class="card-header">
        {{ 'academicCommittee' | translate }}
      </div>
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center"
          *ngFor="let usr of academicCommitteeMembers">
          <div>
            <div>
              <user-file-link [user]="usr"></user-file-link>
            </div>
            <div *ngIf="usr.email">
              <email-link [user]="usr"></email-link>
            </div>
          </div>
          <div>
            <a [routerLink]="null"
              role="button"
              class="text-muted"
              *ngIf="!coordinator || usr._id !== coordinator._id"
              [tooltip]="'remove' | translate"
              (click)="removeAC(usr)">
              <i class="far fa-times"></i>
            </a>
            <span class="badge badge-info"
              *ngIf="coordinator && usr._id === coordinator._id">
              {{ 'coordinator' | translate }}
            </span>
          </div>
        </div>
        <div class="list-group-item"
          *ngIf="academicCommitteeMembers.length <= 0">
          <i>
            {{ 'empty-academicCommittee' | translate}}
          </i>
        </div>
        <div class="list-group-item"
          *ngIf="us.isService()">
          <user-selector #addACUserSelector
            [sm]="true"
            [(ngModel)]="addACUser"
            [name]="'addAC'"></user-selector>
          <div class="text-right">
            <button [routerLink]="null"
              role="button"
              class="btn btn-sm"
              [class.btn-outline-primary]="!addACUser"
              [class.btn-primary]="addACUser"
              (click)="addAC()"
              [disabled]="!addACUser">
              <i class="far fa-plus mr-1"></i>
              {{ 'add' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="card-header">
        {{ 'modify-evaluations-auth' | translate }}
      </div>
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center"
          *ngFor="let usr of modifyEvaluationsAuth">
          <div>
            <user-file-link [user]="usr"></user-file-link>
          </div>
          <div>
            <a [routerLink]="null"
              role="button"
              class="text-muted"
              [tooltip]="'remove-authorization' | translate"
              (click)="removeAuth(usr)">
              <i class="far fa-times"></i>
            </a>
          </div>
        </div>
        <div class="list-group-item"
          *ngIf="modifyEvaluationsAuth.length <= 0">
          <i>
            {{ 'empty-modify-evaluations-auth' | translate}}
          </i>
        </div>
        <div class="list-group-item"
          *ngIf="us.isService()">
          <user-selector #addAuthUserSelector
            [sm]="true"
            [(ngModel)]="addAuthUser"
            [name]="'addAut'"></user-selector>
          <div class="text-right">
            <button [routerLink]="null"
              role="button"
              class="btn btn-sm"
              [class.btn-outline-primary]="!addAuthUser"
              [class.btn-primary]="addAuthUser"
              (click)="addAuth()"
              [disabled]="!addAuthUser">
              <i class="far fa-plus mr-1"></i>
              {{ 'authorize' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        {{ 'studentRepresentatives' | translate }}
      </div>
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center"
          *ngFor="let usr of studentRepresentatives">
          <div>
            <div>
              <user-file-link [user]="usr"></user-file-link>
            </div>
            <div *ngIf="usr.email">
              <email-link [user]="usr"></email-link>
            </div>
          </div>
          <div>
            <a [routerLink]="null"
              role="button"
              class="text-muted"
              [tooltip]="'remove' | translate"
              (click)="removeSR(usr)">
              <i class="far fa-times"></i>
            </a>
          </div>
        </div>
        <div class="list-group-item"
          *ngIf="studentRepresentatives.length <= 0">
          <i>
            {{ 'empty-studentRepresentatives' | translate}}
          </i>
        </div>
        <div class="list-group-item"
          *ngIf="us.isService()">
          <user-selector #addSRUserSelector
            [sm]="true"
            [(ngModel)]="addSRUser"
            [name]="'addAC'"></user-selector>
          <div class="text-right">
            <button [routerLink]="null"
              role="button"
              class="btn btn-sm"
              [class.btn-outline-primary]="!addSRUser"
              [class.btn-primary]="addSRUser"
              (click)="addSR()"
              [disabled]="!addSRUser">
              <i class="far fa-plus mr-1"></i>
              {{ 'add' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<ng-template #removeLineConfirmationTemplate>
  <div class="form-group">
    <label for="lineOfResearchSelect">
      {{ 'line-of-research-to-change' | translate:{lr: lineToRemove.name} }}
    </label>
    <ng-select id="lineOfResearchSelect"
      name="lineOfResearchSelect"
      [items]="linesOfResearchToSwitch"
      bindValue="_id"
      bindLabel="name"
      [(ngModel)]="newLineToSwithId"
      [placeholder]="'select-line-of-research' | translate"
      [searchable]="false"
      [clearable]="true">
    </ng-select>
    <small class="form-text text-muted">
      {{ 'line-of-research-to-change-msg' | translate:{lr: lineToRemove.name} }}
    </small>
  </div>
</ng-template>

<modal [title]="'edit-line-of-research' | translate"
  [(open)]="openEditLine">
  <form id="editLineForm"
    (ngSubmit)="editLineOfResearch()"
    #editLineForm="ngForm">
    <div class="form-group">
      <label for="lineNewName">
        {{ 'name' | translate}}
      </label>
      <input type="text"
        class="form-control"
        [placeholder]="'line-of-research-name' | translate"
        id="lineNewName"
        name="lineNewName"
        #lineNewNameInput="ngModel"
        required
        [class.is-invalid]="editLineForm.submitted && lineNewNameInput.invalid"
        [(ngModel)]="lineNewName">
      <validation-errors [show]="editLineForm.submitted"
        [control]="lineNewNameInput"></validation-errors>
    </div>
  </form>
  <div footer>
    <button class="btn btn-outline-primary"
      type="submit"
      form="editLineForm">
      {{ 'save' | translate }}
    </button>

  </div>
</modal>

<modal [title]="'edit-program' | translate"
  [(open)]="editOpen">
  <form id="editProgramForm"
    (ngSubmit)="saveProgram()"
    *ngIf="programEdit"
    #editProgramForm="ngForm">
    <div class="form-group">
      <label for="">
        {{ 'name' | translate}}
      </label>
      <input type="text"
        class="form-control"
        [placeholder]="'program-name' | translate"
        id="programName"
        name="programName"
        #programNameInput="ngModel"
        required
        [class.is-invalid]="editProgramForm.submitted && programNameInput.invalid"
        [(ngModel)]="programEdit.name">
      <validation-errors [show]="editProgramForm.submitted"
        [control]="programNameInput"></validation-errors>
    </div>
    <div class="form-group">
      <label for="facultySelect">
        {{ 'faculties' | translate }}
      </label>
      <ng-select id="facultiesSelect"
        name="facultiesSelect"
        #facultiesSelect
        [items]="faculties"
        bindValue="_id"
        bindLabel="name"
        [(ngModel)]="programEdit.faculties"
        [multiple]="true"
        [required]="true"
        [searchable]="true"
        [clearable]="true">
      </ng-select>
      <validation-errors [show]="editProgramForm.submitted"
        [control]="facultiesSelect"></validation-errors>
    </div>
    <div class="form-group">
      <label for="facultySelect">
        {{ 'secretary-faculty' | translate }}
      </label>
      <ng-select id="secretaryFacultySelect"
        name="secretaryFacultySelect"
        #secretaryFaculty
        [items]="faculties"
        bindValue="_id"
        bindLabel="name"
        [(ngModel)]="programEdit.secretaryFacultyId"
        [required]="true"
        [searchable]="true"
        [clearable]="false">
      </ng-select>
      <validation-errors [show]="editProgramForm.submitted"
        [control]="secretaryFaculty"></validation-errors>
    </div>
    <div class="form-group">
      <label for="coordinatorSelect">
        {{ 'coordinator' | translate }}
      </label>
      <ng-select id="coordinatorSelect"
        name="coordinatorSelect"
        #coordinatorSelect
        [items]="academicCommitteeMembers"
        bindValue="_id"
        bindLabel="name"
        [(ngModel)]="programEdit.coordinatorId"
        [required]="true"
        [searchable]="true"
        [clearable]="false">
        <ng-template ng-option-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
      </ng-select>
      <validation-errors [show]="editProgramForm.submitted"
        [control]="coordinatorSelect"></validation-errors>
    </div>
  </form>
  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="editOpen = false">
      {{ 'cancel' | translate }}
    </button>
    <button class="btn btn-primary"
      type="submit"
      form="editProgramForm">
      {{ 'save' | translate }}
    </button>

  </div>
</modal>