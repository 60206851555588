import { PlatformLocation } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { PermissionsType } from '../../../../../../interfaces/IPermissions';
import { IStudent } from '../../../../../../interfaces/IStudent';
import { ITask, Processes } from '../../../../../../interfaces/ITask';
import { IThesisCommitteeMemberData, IThesisProcess, TKThesis, ThesisDefenceModality, ThesisFinalEvaluation, ThesisPState } from '../../../../../../interfaces/IThesisProcess';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { ThesisHttpService } from '../../../../data-services/thesis-http.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';
import { LoadingIndicatorService } from '../../../../UI/loading-indicator/loading-indicator.service';
import { UserService } from '../../../../user/user.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { VideoConferenceThesisModalComponent } from './video-conference-thesis-modal/video-conference-thesis-modal.component';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { languages_list } from '../../../../../../utils/Languages';
import { zip } from 'rxjs';
import { IProcessHistory } from '../../../../../../interfaces/IProcessHistory';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { ThesisReportsModalComponent } from './thesis-reports-modal/thesis-reports-modal.component';
import * as _ from 'lodash';

@Component({
  selector: 'student-thesis-view',
  templateUrl: './student-thesis-view.component.html',
})
export class StudentThesisViewComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @ViewChild('videoConferenceModal') videoConferenceModal: VideoConferenceThesisModalComponent;
  @ViewChild('thesisReportsModal') thesisReportsModal: ThesisReportsModalComponent;

  @Input() student: IStudent;
  @Input() requestEnabled: { enabled: boolean, reasons: string[] };

  process: IThesisProcess;
  history: IProcessHistory;

  loading = true;

  open = false;
  task = null;
  generated = false;
  token;
  url;

  openAskForCumLaudeVote = false;
  presidentId: string;
  presidentModality: ThesisDefenceModality;
  secretaryId: string;
  secretaryModality: ThesisDefenceModality;
  memberId: string;
  memberModality: ThesisDefenceModality;
  members: IThesisCommitteeMemberData[] = [];
  pendingCumLaudeVotes = false;

  acceptedForProcessing;
  defenceRequested;

  thesis;

  ThesisFinalEvaluation = ThesisFinalEvaluation;
  PermissionsType = PermissionsType;
  Processes = Processes;
  ThesisPState = ThesisPState;
  ThesisDefenceModality = ThesisDefenceModality;

  languages = languages_list;
  defenceLanguage;

  requestDoc: IDocumentRef;
  olderRequestDocs: IDocumentRef[] = [];

  constructor(private ths: ThesisHttpService, private ls: LoadingIndicatorService,
    public hs: HelperService, public sh: ServiceHttpService,
    private router: Router, private platformLocation: PlatformLocation,
    public us: UserService,
    private alerts: PopAlertsService,
    private cds: ConfirmationDialogService,
    private ts: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.student) {
      this.refresh();
    }
  }

  openVideoConferenceModal() {
    this.videoConferenceModal.videoConferenceDocs = [...this.thesis.videoConferenceDocs];
    this.videoConferenceModal.open = true
  }

  private refresh() {
    this.loading = true;
    this.ls.start();
    zip(
      this.ths.getTable(this.student._id),
      this.ths.findHistory(this.student._id)
    ).subscribe((res) => {
      this.process = res[0];
      this.history = res[1];
      if (this.process) {
        this.thesis = {
          ...this.process,
          ...this.process.data
        }
        this.members.push(this.process.data.thesisCommittee?.president);
        this.members.push(this.process.data.thesisCommittee?.secretary);
        this.members.push(this.process.data.thesisCommittee?.member);
        this.members.push(this.process.data.thesisCommittee?.alt1);
        this.members.push(this.process.data.thesisCommittee?.alt2);
        this.members.push(this.process.data.thesisCommittee?.alt3);

        this.pendingCumLaudeVotes = this.process.tasks.some(t => t.taskKey === TKThesis.cumLaudeVote)

        this.requestDoc = this.process.requestDoc;
        this.olderRequestDocs = this.process.olderRequestDocs;
      } else {
        this.thesis = this.student.thesis;
        if (this.history) {
          this.olderRequestDocs = this.history.requestDocs;
        }
      }

      if (this.thesis && this.thesis.defenceLanguage) {
        this.defenceLanguage = this.languages.find(l => l.code === this.thesis.defenceLanguage);
      }
      this.acceptedForProcessing = this.calcAcceptedForProcessing();
      this.loading = false;
      this.ls.stop();
    });
  }

  private calcAcceptedForProcessing() {
    if (!this.process) return false;
    return this.process.state.toString().includes('Defence')
  }

  generate() {
    this.sh.refreshToken(this.task._id).subscribe((res) => {
      this.token = res.token;
      this.url = this.router.serializeUrl(this.router.createUrlTree(['/task', this.task._id, this.token]));
      this.url = (this.platformLocation as any).location.origin + this.url;
      this.generated = true;
    });
  }

  generateProposal() {
    window.location.href = `${window.location.origin}/api/service/proposalTemplate/${this.student._id}`
  }

  generateDesignation() {
    window.location.href = `${window.location.origin}/api/service/designationTemplate/${this.student._id}`
  }

  generateDefenceRecord() {
    window.location.href = `${window.location.origin}/api/service/defenceRecordTemplate/${this.student._id}`
  }

  generateContentRelation() {
    window.location.href = `${window.location.origin}/api/service/contentRelationTemplate/${this.student._id}`
  }

  saveExpenseDocDate(event) {
    this.ls.start();
    this.sh.saveExpenseDocDate(this.student._id, event).subscribe(() => {
      this.alerts.saveSuccess();
      this.ls.stop();
    });
  }

  changeExternalEvaluator(task: ITask) {
    if (!this.us.isService()) return;

    const originalEvaluatorName = task.assigneeName;

    let newEvaluator;
    if (this.process.data.externalEvaluators.ev1._id !== this.process.selectedEvaluators.ev1._id
      && this.process.data.externalEvaluators.ev1._id !== this.process.selectedEvaluators.ev2._id) {
      newEvaluator = this.process.data.externalEvaluators.ev1;
    } else if (this.process.data.externalEvaluators.ev2._id !== this.process.selectedEvaluators.ev1._id
      && this.process.data.externalEvaluators.ev2._id !== this.process.selectedEvaluators.ev2._id) {
      newEvaluator = this.process.data.externalEvaluators.ev2;
    } else if (this.process.data.externalEvaluators.ev3._id !== this.process.selectedEvaluators.ev1._id
      && this.process.data.externalEvaluators.ev3._id !== this.process.selectedEvaluators.ev2._id) {
      newEvaluator = this.process.data.externalEvaluators.ev3;
    }
    const newEvaluatorName = newEvaluator.lastName + ', ' + newEvaluator.firstName;

    this.cds.confirm({
      title: this.ts.instant('change-external-evaluator'),
      message: this.ts.instant('change-external-evaluator-help', { originalEvaluatorName, newEvaluatorName, email: newEvaluator.email }),
      btnOkClass: 'primary',
      btnOkText: this.ts.instant('accept'),
    }, () => {
      this.sh.changeExternalEvaluator(task._id).subscribe(() => {
        this.alerts.saveSuccess();
        this.refresh();
      });
    })
  }

  doOpenAskForCumLaudeVote() {
    if (this.process.finalThesisCommittee) {
      this.presidentId = this.process.finalThesisCommittee.president._id;
      this.secretaryId = this.process.finalThesisCommittee.secretary._id;
      this.memberId = this.process.finalThesisCommittee.member._id;
    } else {
      this.presidentId = this.process.data.thesisCommittee.president._id;
      this.secretaryId = this.process.data.thesisCommittee.secretary._id;
      this.memberId = this.process.data.thesisCommittee.member._id;
    }
    this.openAskForCumLaudeVote = true;
  }

  assignPresident(userId) {
    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.presidentId = userId;
  }

  assignSecretary(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.secretaryId = userId;
  }

  assignMember(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    this.memberId = userId;
  }

  askForCumLaudeVote() {
    if (!this.f.valid) return;

    this.ths.serviceAskForCumLaudeVotes(this.process._id, {
      presidentId: this.presidentId,
      presidentModality: this.presidentModality,
      secretaryId: this.secretaryId,
      secretaryModality: this.secretaryModality,
      memberId: this.memberId,
      memberModality: this.memberModality
    }).subscribe(() => {
      this.alerts.saveSuccess();
      this.openAskForCumLaudeVote = false;
      this.refresh();
    });
  }

  stopCumLaudeVotes() {
    this.cds.confirm({
      title: 'Detener votaciones de cum laude',
      message: '¿Está seguro de que desea detener las votaciones de cum laude?',
      btnOkClass: 'warning',
      btnOkText: this.ts.instant('accept'),
    }, () => {
      this.ths.serviceStopCumLaudeVotes(this.process._id).subscribe(() => {
        this.alerts.saveSuccess();
        this.refresh();
      });
    })
  }

  openThesisReportsModal() {
    this.thesisReportsModal.reports = _.cloneDeep(this.thesis.reports);
    this.thesisReportsModal.open = true;
  }

}
