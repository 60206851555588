import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment-timezone';

import { ITask } from '../../../../interfaces/ITask';
import { DADUtils } from '../../../../utils/DADUtils';
import { ExternalTaskHttpService } from '../../data-services/external-task-http.service';
import { AbstractTaskComponent } from '../../root/components/process/abstract-task.component';
import { IThesisCommitteeMemberData, ThesisDefenceModality } from '../../../../interfaces/IThesisProcess';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'thesis-defence-date',
  templateUrl: './thesis-defence-date.component.html',
})
export class ThesisDefenceDateComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() task: ITask;
  @Input() token: string;

  @Output() onComplete = new EventEmitter();

  defenceType: "videoconference" | "presential" | "hybrid" = null;

  defenceDate: Date;
  defencePlace: string;

  minDefenceDate: Date;

  members: IThesisCommitteeMemberData[] = [];
  presidentId: string;
  presidentName: string;
  presidentModality: ThesisDefenceModality;
  secretaryId: string;
  secretaryName: string;
  secretaryModality: ThesisDefenceModality;
  memberId: string;
  memberName: string;
  memberModality: ThesisDefenceModality;

  ThesisDefenceModality = ThesisDefenceModality;

  constructor(private et: ExternalTaskHttpService,
    private ts: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.minDefenceDate = DADUtils.add16DaysForDefence(this.task.thesisProcess.pgcDefenceApprobalDate).toDate();
    const process = this.task.thesisProcess;
    this.members.push(process.data.thesisCommittee?.president);
    this.members.push(process.data.thesisCommittee?.secretary);
    this.members.push(process.data.thesisCommittee?.member);
    this.members.push(process.data.thesisCommittee?.alt1);
    this.members.push(process.data.thesisCommittee?.alt2);
    this.members.push(process.data.thesisCommittee?.alt3);

    this.presidentId = process.data.thesisCommittee.president._id;
    this.presidentName = DADUtils.fullname(process.data.thesisCommittee.president);
    this.secretaryId = process.data.thesisCommittee.secretary._id;
    this.secretaryName = DADUtils.fullname(process.data.thesisCommittee.secretary);
    this.memberId = process.data.thesisCommittee.member._id;
    this.memberName = DADUtils.fullname(process.data.thesisCommittee.member);
  }

  assignPresident(userId) {
    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.presidentId = userId;
  }

  assignSecretary(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.secretaryId = userId;
  }

  assignMember(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    this.memberId = userId;
  }

  manageSend() {
    if (this.f.invalid || this.dateError() || this.isHybridError()) {
      this.sendEnd.next();
      return;
    }

    this.defenceDate = moment.tz(moment(this.defenceDate).format('YYYY-MM-DD HH:mm'), 'Europe/Madrid').toDate();

    if (this.defenceType === 'videoconference') {
      this.defencePlace = this.ts.instant('videoconference');
      this.presidentModality = ThesisDefenceModality.online;
      this.secretaryModality = ThesisDefenceModality.online;
      this.memberModality = ThesisDefenceModality.online;
    } else if (this.defenceType === 'presential') {
      this.presidentModality = ThesisDefenceModality.inPerson;
      this.secretaryModality = ThesisDefenceModality.inPerson;
      this.memberModality = ThesisDefenceModality.inPerson;
    }

    this.et.defenceDate(this.task._id, this.token, {
      defenceDate: this.defenceDate,
      defencePlace: this.defencePlace,
      defenceType: this.defenceType,
      presidentId: this.presidentId,
      presidentModality: this.presidentModality,
      secretaryId: this.secretaryId,
      secretaryModality: this.secretaryModality,
      memberId: this.memberId,
      memberModality: this.memberModality
    }).subscribe(() => {
      this.sendEnd.next();
      this.onComplete.emit();
    })
  }

  dateError() {
    if (!this.defenceDate) return false;
    return moment(this.defenceDate).isBefore(this.minDefenceDate, 'd')
  }

  isHybridError() {
    // check if all modalities are the same
    return this.defenceType === 'hybrid' && this.presidentModality === this.secretaryModality && this.presidentModality === this.memberModality;
  }

}
