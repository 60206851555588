import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { IFaculty } from '../../../../../../interfaces/IFaculty';
import { IProgram } from '../../../../../../interfaces/IProgram';
import { IStudent } from '../../../../../../interfaces/IStudent';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { ILineOfResearch } from '../../../../../../interfaces/ILineOfResearch';

@Component({
  selector: 'program-edit-modal',
  templateUrl: './program-edit-modal.component.html',
})
export class ProgramEditModalComponent implements OnInit, OnChanges {
  @ViewChild('f') f: NgForm;
  @Input() open = false;
  @Output() openChange = new EventEmitter<boolean>();
  @Input() student: IStudent;

  faculties: IFaculty[];
  linesOfResearch: ILineOfResearch[];

  program: IProgram;
  facultyId: string;
  lineOfResearchId: string;

  constructor(public ps: ProgramHttpService,
    private ss: ServiceHttpService,
    private alerts: PopAlertsService,
    private refresh: RefreshService) { }

  ngOnInit() {
  }

  close() {
    this.open = false;
    this.openChange.emit(this.open);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.student || changes.open) {
      this.program = this.ps.programs.find(p => p._id === this.student.programId);
      this.facultyId = this.student.facultyId;
      this.lineOfResearchId = this.student.lineOfResearchId;
      this.resetFaculties();
      this.resetLinesOfResearch();
    }
  }

  resetFaculties() {
    if (!this.program) {
      this.faculties = [];
    } else {
      this.faculties = [this.program.faculty]
      if (this.program.otherFaculties && this.program.otherFaculties.length > 0) {
        this.faculties = this.faculties.concat(this.program.otherFaculties);
      }
      if (this.program.secretaryFaculty) {
        this.faculties.push(this.program.secretaryFaculty);
      }
    }

    if (!this.faculties.find(f => f._id === this.facultyId)) {
      this.facultyId = this.faculties.length > 0 ? this.faculties[0]._id : null;
    }
  }

  resetLinesOfResearch() {
    if (!this.program) {
      this.linesOfResearch = [];
    } else {
      this.ps.linesOfResearch(this.program._id).subscribe((lines) => {
        this.linesOfResearch = lines;
        if (!this.linesOfResearch.find(l => l._id === this.lineOfResearchId)) {
          this.lineOfResearchId = this.linesOfResearch.length > 0 ? this.linesOfResearch[0]._id : null;
        }
      });
    }
  }

  save() {
    if (!this.f.valid) {
      return;
    }

    this.ss.editProgram(this.student._id, this.program._id, this.facultyId, this.lineOfResearchId).subscribe(() => {
      this.close();
      this.refresh.onStudentChange.next()
      this.alerts.saveSuccess();
    })

  }

}
