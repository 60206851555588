import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingIndicatorService } from '../../../../UI/loading-indicator/loading-indicator.service';
import { FacultyHttpService } from '../../../../data-services/faculty-http.service';
import { IFaculty } from '../../../../../../interfaces/IFaculty';
import { IProgram } from '../../../../../../interfaces/IProgram';
import { zip } from 'rxjs';
import { IUser } from '../../../../../../interfaces/IUser';
import * as _ from 'lodash';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { UserService } from '../../../../user/user.service';
import { UserSelectorComponent } from '../../../../UI/user-selector/user-selector.component';

@Component({
  selector: 'faculty-file',
  templateUrl: 'faculty-file.component.html'
})

export class FacultyFileComponent implements OnInit {
  @ViewChild('addUserSelector') addUserSelector: UserSelectorComponent;

  faculty: IFaculty;
  programs: IProgram[] = [];
  programsSecretary: IProgram[] = [];

  editOpen = false;
  facultyEdit: IFaculty;
  director: IUser;

  addUser: IUser;

  chiefSecretary: IUser;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private loading: LoadingIndicatorService,
    private fs: FacultyHttpService,
    private alerts: PopAlertsService,
    public us: UserService) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (!params.facultyId) {
        void this.router.navigate(['../../'], { relativeTo: this.route });
        return;
      }
      this.loading.start();
      zip(
        this.fs.fetch(params.facultyId),
        this.fs.programs(params.facultyId),
        this.fs.programsSecretary(params.facultyId)
      ).subscribe((res) => {
        this.faculty = res[0];
        this.programs = res[1];
        this.programsSecretary = res[2];

        this.chiefSecretary = this.faculty.chiefSecretary;

        this.loading.stop();
      })
    })
  }

  openEditModal() {
    this.facultyEdit = _.cloneDeep(this.faculty);
    this.director = this.facultyEdit.director;
    this.editOpen = true;
  }

  save() {
    this.fs.edit(this.faculty._id,
      this.facultyEdit.name,
      this.director ? this.director._id : null,
      this.facultyEdit.chiefSecretaryId ? this.facultyEdit.chiefSecretaryId : null).subscribe(() => {
        this.faculty = this.facultyEdit;
        this.faculty.director = this.director;
        if (this.facultyEdit.chiefSecretaryId) {
          this.chiefSecretary = this.faculty.secretaries.find(s => s._id.toString() === this.facultyEdit.chiefSecretaryId);
          this.faculty.chiefSecretaryId = this.chiefSecretary._id;
        }
        this.alerts.saveSuccess();
        this.editOpen = false;
      })
  }

  addSecretary() {
    if (!this.addUser) return;

    this.fs.addSecretary(this.faculty._id, this.addUser._id).subscribe(() => {
      this.faculty.secretaries.push(this.addUser);
      this.faculty.secretariesIds.push(this.addUser._id);
      this.addUser = null;
      this.addUserSelector.resetFind();
      this.addUserSelector.email = '';
      this.alerts.saveSuccess();
    })
  }

  removeSecretary(usr) {
    this.fs.removeSecretary(this.faculty._id, usr._id).subscribe(() => {
      this.faculty.secretaries = this.faculty.secretaries.filter(s => s._id.toString() !== usr._id.toString());
      this.alerts.saveSuccess();
    })
  }
}