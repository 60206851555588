import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IFaculty } from "../../../interfaces/IFaculty";
import { HttpClient } from "@angular/common/http";
import { IProgram } from "../../../interfaces/IProgram";

@Injectable()
export class FacultyHttpService {

  constructor(private http: HttpClient) { }

  fetchFaculties(): Observable<IFaculty[]> {
    return this.http.get<IFaculty[]>(`/api/faculty/getAll`);
  }

  fetch(facultyId: string): Observable<IFaculty> {
    return this.http.get<IFaculty>(`/api/faculty/fetch/${facultyId}`)
  }

  programs(facultyId: string): Observable<IProgram[]> {
    return this.http.get<IProgram[]>(`/api/faculty/programs/${facultyId}`)
  }

  programsSecretary(facultyId: string): Observable<IProgram[]> {
    return this.http.get<IProgram[]>(`/api/faculty/programsSecretary/${facultyId}`)
  }

  edit(facultyId: string, name: string, directorId: string, chiefSecretaryId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/faculty/${facultyId}/edit`, { name, directorId, chiefSecretaryId })
  }

  addSecretary(facultyId: string, userId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/faculty/${facultyId}/secretaries/add`, { userId })
  }

  removeSecretary(facultyId: string, userId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/faculty/${facultyId}/secretaries/remove`, { userId })
  }


}